import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-9a.png'
import image2 from 'images/project-9b.png'
import image3 from 'images/project-9c.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Rental cabins and caravans - an affordable option"
      meta={[
        {
          name: 'description',
          content:
            'Families in need of more space have benefited from our temporary, affordable rentals. ',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Rental cabins and caravans – an affordable option"
      intro="Families in need of more space have benefited from our temporary, affordable rentals."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Four cosy cabins are currently rented out across the Wellington
            region. Water-tight, and well-insulated, the modern dwellings were
            constructed by building apprentices under the supervision of expert
            Bill Manuel.
          </Paragraph>
          <Paragraph>
            Two renovated caravans with new interior joinery, carpet, beds and a
            repaint have gone to good homes. The spaces offer privacy and warmth
            for extra families members.
          </Paragraph>
          <Paragraph>
            The cabins and caravans are rented out at an agreed weekly price
            with families.
          </Paragraph>
          <img className={styles.image} src={image3} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
